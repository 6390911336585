import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {TextField,InputLabel,MenuItem,FormControl
        ,Select,Grid,LinearProgress, Box, IconButton, Button, Typography} from "@material-ui/core";
//import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import axios from "axios";
import qs from "qs";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {authUser} from "../funciones/AuthUser";
import {AuthContext} from "../context/AuthContext";
import {GeneralesContext} from '../context/GeneralesContext'
import GraficaEnergia from "./GraficaCarroTensor";
import GraficaMensual from "./GraficaMensual";
import TablaCarroTensor from "./TablaCarroTensor";
import {ErrorGeneral,  llamadaApiToken,llamaApiCargaToken} from "../funciones/ClienteAxios";
import Relojes from './Relojes2'
import {toast} from 'react-toastify';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  botones: {
   // marginTop:theme.spacing(3),
  },
  formStyle: {
    marginBottom: 5,
    //justifyContent: "left",
    flexDirection:"row",
    display:"flex"
  },
  
  graficainicial:{
    marginLeft: '1em',
  },
  graficasiguiente:{
    marginTop: ".1em",
    marginLeft: '1em',
  },
}));

export default function CarroTensorInicio() {
  const classes = useStyles();
  const historial = useHistory();
  let nvlSNPermiso ='5';
  const idusu = localStorage.getItem("UsuId");
  const usu= localStorage.getItem('UsuarioNom');
  const nvl= localStorage.getItem('Tipo');  
  const source = axios.CancelToken.source();
  const [terminal, setTerminal] = useState(0);
  const [auxTerminal, setAuxTerminal] = useState(0);
  const [actualiza, setActualiza] = useState(false);
  const [listaTerminales, setListaTerminales] = useState([]);
  const [listaCTensor, setListaCTensor] = useState([]);  
  const [listMensual, setListMensual] = useState([]);  
  const [espera, setEspera] = useState(false);    
  const [bandera, setBandera] = useState(1);    
  const {usuario,guardarUsuario}=React.useContext(AuthContext);
  const {guardarGenerales}=React.useContext(GeneralesContext);  
  
  const [fechasSeleccionadas, setFechasSeleccionadas] = useState({inicio: new Date(), final: new Date()});     
  const [dataGrafica, setDataGrafica] = useState({data:[],labels:[],estacion:""}); 
  const [dataMensual, setDataMensual] = useState({data:[],labels:[],estacion:""}); 
  const [valorEnlo, setValorEnlo] = useState(0); 
  const [vFinal, setVFinal] = useState(0.0); 
  const [vInicial, setVInicial] = useState(0.0); 
  //const {vInicial,vFinal} =valores
  useEffect(() => {        

    const autenticado = authUser();
    if(!autenticado){
      localStorage.clear();
      historial.push('/');
      guardarUsuario([]);
      guardarGenerales([])     
    }
    else
    { const usuTerminal= localStorage.getItem('UsuTerminal'); 
      guardarUsuario({Nombre: usu, nivel: nvl});
      guardarGenerales({Titulo:'Carro Tensor'}) 
      getTerminales();
      setTerminal(parseInt(usuTerminal))
      generarTabla(parseInt(usuTerminal))
      getListMensual(parseInt(usuTerminal))
      getListElongaciom(parseInt(usuTerminal))
    }

    return () => {
      source.cancel();
    }
  }, []);

  useEffect(()=>{
    if (auxTerminal!==0) {
      generarTabla(auxTerminal)
      getListMensual(auxTerminal)
      getListElongaciom(auxTerminal)
    }
    
  },[actualiza])

  useEffect(() => {
    if (listaCTensor.length!==0 && bandera===0) 
    {
      datosGrafica(listaCTensor,setDataGrafica, "lista modificada")
    }
    else{
      if (bandera) {
        setBandera(0)
      }
    }
  
    
  }, [listaCTensor])
  
  const getTerminales = () => {
    const data = qs.stringify({
      usuario: idusu,
      idDispositivo: '987gyubjhkkjh'
    });
    const url = "/energia-terminal";

    function respuesta(aux) {
      if (aux[0].Id>-1) {
        let aux1 = [];
        aux1.push({
          Id: aux[0].Id,
          Descr: "Seleccione",
        });
        for (let i = 1; i < aux.length; i++) {
          aux1.push(aux[i]);
        }
        setListaTerminales(aux1);
        let auxterminal = aux1.filter(function (val) {
          return val.Id !== 0;
        });
        auxterminal.splice(0,0,{
          Id: 0,
          Descr: "Seleccione",
        });              
        
      }
    }
    llamadaApiToken(data, url, respuesta, ErrorGeneral, historial, source)
  };

  const handleChange = (event) => {
    setTerminal(event.target.value);    
  };

  function datosGrafica(lista,setDataGrafica,lugar){        
    let listFech = [], listTensor = [];    
    if (lista[0].termNom==="Bucle A") 
    {
      lista.forEach(registro => { 
        listFech.push(moment.utc(registro.fAlta).local(true).format("DD/MM/YYYY"));
        listTensor.push(registro.tensorE1 )             
      })  
    }
    else
    {
      if (lista[0].termNom==="Bucle C") 
      {
        lista.forEach(registro => { 
          listFech.push(moment.utc(registro.fAlta).local(true).format("DD/MM/YYYY"));
          listTensor.push(registro.tensorE5B )             
        })  
      }
      else
      { lista.forEach(registro => { 
          listFech.push(moment.utc(registro.fAlta).local(true).format("DD/MM/YYYY"));
          listTensor.push(registro.tensorE5C )             
        }) 
      }
    }    
    //console.log("cambiando datos",lugar, bandera);
    setDataGrafica({data:listTensor,labels:listFech,estacion:lista[0].termNom})

  } 

  function datosGraficaMensual(lista,setDataGrafica,lugar){        
    let listFech = [], listTensor = [];    
    if (lista[0].termNom==="Bucle A") 
    {
      lista.forEach(registro => { 
        listFech.push(moment.utc(registro.fAlta).local(true).format("DD"));
        listTensor.push(registro.tensorE1 )             
      })  
    }
    else
    {
      if (lista[0].termNom==="Bucle C") 
      {
        lista.forEach(registro => { 
          listFech.push(moment.utc(registro.fAlta).local(true).format("DD"));
          listTensor.push(registro.tensorE5B )             
        })  
      }
      else
      { lista.forEach(registro => { 
          listFech.push(moment.utc(registro.fAlta).local(true).format("DD"));
          listTensor.push(registro.tensorE5C )             
        }) 
      }
    }    
    //console.log("cambiando datos",lugar, bandera);
    setDataGrafica({data:listTensor,labels:listFech,estacion:lista[0].termNom})

  } 

  function generarTabla(auxTerminal){
    setDataGrafica({data:[],labels:[],estacion:""})
    const guardaNuevaFalla = () => {
      const fInicio = moment(fechasSeleccionadas.inicio).format("YYYY-MM-DD")
      const fFinal = moment(fechasSeleccionadas.final).format("YYYY-MM-DD")
      const data = qs.stringify({
        fechaIni: fInicio+'T00:00:00',
        fechaFin:fFinal+'T23:59:59',
        idDispositivo: "EBRDVDVDVFFD",
        terminal:terminal!==0?terminal:auxTerminal,
      });
      const url = "/bitacora-ctensor-intervalo-web-1-1";

      function response(aux) {
        //console.log(aux)
        if (aux[0].Id> -1) {
          setListaCTensor(aux);          
          datosGrafica(aux.reverse(),setDataGrafica, "api")
          setBandera(1)
          setAuxTerminal(terminal!==0?terminal:auxTerminal)
        }
      }
      llamaApiCargaToken(data, url,response,ErrorGeneral,setEspera,historial,source);
    }
    guardaNuevaFalla()
  }

  
  function getListMensual(auxTerminal){
            
    const data = qs.stringify({      
      idDispositivo: "EBRDVDVDVFFD",
      terminal:terminal!==0?terminal:auxTerminal,
    });
    const url = "/bitacora/ctensor/v2/intervalo/mensual";

    function response(aux) {    
      if (aux[0].Id> -1) {
        setListMensual(aux)
        datosGraficaMensual(aux,setDataMensual, "mensual")        
      }
    }
    llamaApiCargaToken(data, url,response,ErrorGeneral,setEspera,historial,source);      
  }

  function getListElongaciom(auxTerminal){
            
    const data = qs.stringify({      
      idDispositivo: "EBRDVDVDVFFD",
      terminal:terminal!==0?terminal:auxTerminal,
    });
    const url = "/bitacora/ctensor/elongacion";

    function response(aux) {    
      if (aux[0].keyIni> -1) {
        //setValores({vInicial:aux[0].keyIni, vFinal:aux[0].keyFin})
        //setVInicial(aux[0].keyIni)
      //  setVFinal(aux[0].keyFin)
       setVInicial(parseFloat(aux[0].keyIni))
       setVFinal(parseFloat(aux[0].keyFin))
      }
      setValorEnlo(0)
    }
    llamaApiCargaToken(data, url,response,ErrorGeneral,setEspera,historial,source);      
  }

  function getEditElongaciom(auxTerminal){
            
    const data = qs.stringify({      
      idDispositivo: "EBRDVDVDVFFD",
      Usuario:idusu,      
      vini:vInicial,
      vfin:vFinal,
      terminal:terminal!==0?terminal:auxTerminal,
    });
    const url = "/bitacora/ctensor/elongacion/edit";

    function response(aux) {    
      if (aux.mensaje> -1) {
       
       toast.success("Se Actualizaron los valores correctamente.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
      }
      
    }
    llamaApiCargaToken(data, url,response,ErrorGeneral,setEspera,historial,source);      
  }

/*   const onChange = e =>
  { let expNopermitida= new RegExp('[A-Za-z$@#_&+()/:;!?,<>=]');          
    let expMenos = new RegExp("'");
    let expMas = new RegExp('-');     
    let expCadena = new RegExp('[.0-9$]');             
    if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
        && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
        || e.target.value===' '|| e.target.value==='') 
    {
    setValores({
      ...valores, [e.target.name] : e.target.value  
    })
  }
  }; */

  const onChangeInicial = e =>
  { let expNopermitida= new RegExp('[A-Za-z$@#_&+()/:;!?,<>=]');          
    let expMenos = new RegExp("'");
    let expMas = new RegExp('-');     
    let expCadena = new RegExp('[.0-9$]');             
    if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
        && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
        || e.target.value===' '|| e.target.value==='') 
    {
    setVInicial(e.target.value  )
  }
  };

  const onChangeFinal = e =>
  {  
    setVFinal(e.target.value  )
  
  };

  const operacionEnlogacion=()=>{
  if ( vFinal !=="" && vInicial !=="" ) {
      setValorEnlo((( vFinal - vInicial ) / vFinal * 100 ).toFixed(2))  
      getEditElongaciom() 
    }  
    console.log(typeof vFinal   ,typeof vInicial   );
  }

  const ValorEnlogacion=()=>{
    return(
     <Box display={"flex"} flexDirection={"row"}>
      
      <form className={classes.root} noValidate autoComplete="off">
        <div>
      <TextField id="outlinedVIni"    type="number"        
        label="Valor Inicial"  size="small" value={vInicial} 
        variant="outlined"
        onChange={onChangeInicial} name="vInicial" style={{width:6+'rem'}}                                               
      />
      
      
      <TextField id="outlinedVFin"         variant="outlined"   
        label="Valor Final"  size="small" value={vFinal} type="number"
        onChange={onChangeFinal} name="vFinal" style={{width:6+'rem', marginLeft:".5rem"}}                                               
      />   
      </div>
      </form>
      <Button variant="outlined" style={{marginLeft:".5rem"}} onClick={()=>operacionEnlogacion()}>% Elongación</Button>   
      {valorEnlo!==0 ? <Typography style={{marginLeft:".5rem", marginTop:".5rem"}}>{`${valorEnlo}%`}</Typography>  :null }
     </Box> 
    )
  }
  const tablaCarro = listaCTensor.length > 0 ? <TablaCarroTensor listaCTensor={listaCTensor} listaTerminales={listaCTensor}  
                                              termSelect={auxTerminal} usuarioContext={usuario} setListaCTensor={setListaCTensor}/>
                                          : null;  

  return (  
  <Box  display={"flex"} flexDirection={"row"} style={{paddingLeft:1+'rem'}}>
    <Box  display={"flex"} flexDirection={"column"} style={{ width: '40%' }}>            
      <Box className={classes.formStyle}>
        <FormControl className={classes.formControl} size="small">
          <InputLabel id="inputTerminal">
            Terminal
          </InputLabel>
          <Select
            labelId="inputTerminal"   id="selectTerminal"
            value={terminal} onChange={handleChange}
          >
            {listaTerminales.map((terminal, index) => (
              <MenuItem value={terminal.Id} key={terminal.Id}>
                <em>{terminal.Descr}</em>
              </MenuItem>
            ))}
          </Select>
        </FormControl>      
        <Box style={{marginTop:.5+'rem'}}>
          <Relojes fechasSeleccionadas={fechasSeleccionadas}
            setFechasSeleccionadas={setFechasSeleccionadas}/>
        </Box>    
        <FormControl  className={classes.botones} style={{ marginTop: .5+'rem',marginRight: .5+'rem'}}  >
          <IconButton onClick={()=>setActualiza(!actualiza)}  >
            <RefreshIcon color="primary" />
          </IconButton>
        </FormControl>
      </Box>
      {tablaCarro}
      {listMensual.length>0 && dataMensual.data.length>0 ?
        <Box  flexDirection={"row"}  style={{ width: '25rem' , marginTop: 5}}>          
          <GraficaMensual dataGrafica={dataMensual} />      
        </Box> 
       : null}  
    </Box>
    
    <Box  display={"flex"} flexDirection={"column"} style={{ width: '60%' , marginTop: 10}}>
     {/*  <ValorEnlogacion/> */}
      <Box style={{marginTop:10}}>
      { listaCTensor.length>0 && dataGrafica.data.length>0 ? 
        <GraficaEnergia dataGrafica={dataGrafica} 
          arrayDias={"prueba"}            
        />
      : null}
      </Box>
    </Box>      
    <br/>
      
  </Box>   
  );
}