import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Box,
    Collapse,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import {useHistory} from "react-router-dom";
import EditarOrden from './EditarOrden'
import EditarOrdenDummy from './EditarOrdenDummy'
import CreateIcon from '@material-ui/icons/Create';
import {ErrorGeneral, llamaApiCargaToken} from '../funciones/ClienteAxios';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        minWidth: '100%',
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: process.env.REACT_APP_COLOR
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
});

export default function TablaOrden({
                                       guardarOrdenSeleccionada,
                                       orden,
                                       fechasSeleccionadas,
                                       buscar,
                                       setBuscar,
                                       turno,
                                       usuarioContxt,
                                       terminal,
                                       estatus,
                                       funcionActuliza
                                   }) {
    const classes = useStyles();
    let history = useHistory();
    const [selectedID, setSelectedID] = useState(null);
    const [open, setOpen] = useState(false);
    const [espera, setEspera] = useState(false);
    const [ordenSelecc, setOrdenSelecc] = useState(0);
    const [seleccionadoEdit, setSeleccionadoEdit] = useState([]);
    const [modalAbierto, setModalAbierto] = useState(false);

    const [datos, guardarDatos] = useState([])
    const [sinDatos, guardarSinDatos] = useState(true)
    let nvlSNPermiso = '5'

    useEffect(() => {
        const source = axios.CancelToken.source();
        let fInicio = moment(fechasSeleccionadas.inicio).format("YYYY-MM-DD")
        let fFinal = moment(fechasSeleccionadas.final).format("YYYY-MM-DD")
        const llenaListTabla = () => {
            guardarOrdenSeleccionada([])
            let data = qs.stringify({
                terminal: terminal,
                fechaIni: fInicio + 'T00:00:00',
                fechaFin: fFinal + 'T23:59:59',
                status: estatus,
                idDispositivo: '89y7gttvjhj98h'
            });
            let url = "/orden-lista-intervalo-filtro";

            function respuestaListTabla(aux) {
                if (aux[0].Id !== -1) {
                    guardarSinDatos(true)
                    guardarDatos(aux)
                } else {
                    guardarSinDatos(false)
                }
            }

            llamaApiCargaToken(data, url, respuestaListTabla, ErrorGeneral, setEspera, history, source);
        };

        llenaListTabla();
        return () => {
            source.cancel();
        }
    }, [buscar]);

    const seleccionado = (auxId, auxOrden) => {
        setSelectedID(auxId)
        guardarOrdenSeleccionada(auxOrden)
    }

    function editarFall(auxFalla) {
        setSeleccionadoEdit(auxFalla)
        //guardarOrdenSeleccionada(auxFalla)
        setModalAbierto(true)
    }

    function verMas(auxId) {

        if (auxId === ordenSelecc) {
            setOpen(!open)
        } else {
            setOpen(true)
        }
        setOrdenSelecc(auxId)
    }

    function tipoEstado(estado, idSelecc) {
        let auxEtiqueta = ''
        let auxColor = ''
        if (estado === 0) {
            auxEtiqueta = 'Registrado'
            auxColor = '#F46239'
        } else {
            if (estado === 1) {
                auxEtiqueta = 'Solucionado'
                auxColor = '#049b4f'
            } else {
                if (estado === -1) {
                    auxEtiqueta = 'Cancelado'
                    auxColor = 'red'
                }
            }
        }
        let renglon = <div></div>
        selectedID === idSelecc ?
            renglon =
                <TableCell align="center" style={{color: 'white', fontWeight: 700, width: 100 + 'px'}} padding={'none'}>
                    {auxEtiqueta}
                </TableCell> :
            renglon = <TableCell align="center" style={{color: auxColor, fontWeight: 700, width: 100 + 'px'}}
                                 padding={'none'}>{auxEtiqueta}</TableCell>
        return renglon
    }

    function Row(props) {
        const {row} = props;

        return (
            <>
                <TableRow onClick={() => { seleccionado(row.Id, row); }}
                    className={classes.tableRow} selected={selectedID === row.Id}
                    classes={{hover: classes.hover, selected: classes.selected}}
                >
                    <TableCell align="center" padding={'none'} style={{width: 30 + 'px'}}>
                        <Tooltip title={open && ordenSelecc === row.Id ? 'Ver menos' : 'Ver más'}>
                            <IconButton color="primary" aria-label="expand row" size="small"
                                        onClick={() => verMas(row.Id)}>
                                {open && ordenSelecc === row.Id ? (selectedID === row.Id ?
                                        <KeyboardArrowUpIcon style={{color: 'white'}}/> :
                                        <KeyboardArrowUpIcon/>) :
                                    selectedID === row.Id ? <KeyboardArrowDownIcon style={{color: 'white'}}/> :
                                        <KeyboardArrowDownIcon/>}
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    <TableCell align="center" padding={'none'} style={{width: 30 + 'px'}}>
                        <Tooltip title={'Editar'}>
                            <IconButton aria-label="add circle" size="small" onClick={() => editarFall(row)}
                                        component="span">
                                {selectedID === row.Id ? <CreateIcon style={{color: 'white'}}/> :
                                    <CreateIcon color="primary"/>}
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center" padding={'none'}
                               style={{fontWeight: 700, width: 100 + 'px'}}>
                        {row.Id}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left" padding={'none'} style={{width: 75 + 'px'}}>
                        {moment.utc(row.fecha).local(true).format("DD/MM/YYYY")}
                    </TableCell>
                    {tipoEstado(row.Status, row.Id)}
                </TableRow>
                <TableRow>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={5}>
                        <Collapse in={open && ordenSelecc === row.Id} timeout="auto" unmountOnExit>
                            <Box>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow color="primary">
                                            <TableCell style={{color: process.env.REACT_APP_COLOR}}
                                                       padding={'none'}>Paro/Falla</TableCell>
                                            <TableCell style={{color: process.env.REACT_APP_COLOR}} padding={'none'}>Ubicación</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key={row.Id}>
                                            <TableCell align="left" padding={'none'} style={{paddingRight: .7 + 'rem'}}>
                                                {row.paroId === 0 ? "Ninguno" : row.paroId}
                                            </TableCell>
                                            <TableCell align="left" padding={'none'}>
                                                {row.ubicDescr}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    function tablaCompleta(auxlista) {
        return (
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" padding='none'></TableCell>
                            <TableCell align="center" padding='none'>{auxlista.length}</TableCell>
                            <TableCell align="center" padding='none'>Orden </TableCell>
                            <TableCell align="left" padding='none'>Fecha</TableCell>
                            <TableCell align="center" padding='none'>Estatus</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {auxlista.length !== 0 ? (auxlista.map((row) => (
                            <Row key={row.Id} row={row}/>
                        ))) : null}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (<TableContainer className={classes.container2}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Orden </TableCell>
                            <TableCell align="left">Fecha</TableCell>
                            <TableCell align="left">Estatus</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            className={classes.tableRow}
                        >
                            <TableCell className={classes.tableCell} align="center">Dia</TableCell>
                            <TableCell className={classes.tableCell} align="left">Sin</TableCell>
                            <TableCell className={classes.tableCell} align="left">Reportes</TableCell>

                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    const tablaSinResultados = () => {
        return (<TableContainer className={classes.container2}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Orden </TableCell>
                            <TableCell align="left">Fecha</TableCell>
                            <TableCell align="left">Estatus</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            className={classes.tableRow}
                        >
                            <TableCell className={classes.tableCell} align="center">Orden</TableCell>
                            <TableCell className={classes.tableCell} align="left">Sin</TableCell>
                            <TableCell className={classes.tableCell} align="left">coincidencias</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    function filtro() {
        let info = []
        if (turno.length !== 0) {
            let auxCadena = turno.trim();
            info = datos.filter((dat) => dat.turno.toString().search(auxCadena.toUpperCase()) !== -1)

        } else {
            if (orden.length !== 0) {
                let auxCadena = orden.trim();
                info = datos.filter((dat) => dat.Id.toString().search(auxCadena) !== -1)

            }
        }

        let metodos = null
        if (info.length > 0) {
            metodos = tablaCompleta(info)
        } else {
            metodos = tablaSinResultados()
        }
        return metodos;
    }

    const contenido2 = turno !== '' || orden !== '' ? filtro() : tablaCompleta(datos)
    const contenido = sinDatos && datos.length !== 0 ? contenido2 : tablaSinDatos()
    const tabla = espera ? (<Box><LinearProgress/> </Box>) : (contenido)
    const modal = seleccionadoEdit.length !== 0 && modalAbierto ?
        (usuarioContxt?.nivel !== undefined && usuarioContxt.nivel !== nvlSNPermiso ?
                (<EditarOrden titulo={'Editar Orden de Trabajo ' + seleccionadoEdit.Id} modalAbierto={modalAbierto}
                    setModalAbierto={setModalAbierto} funcionActuliza={funcionActuliza}
                    seleccionadoEdit={seleccionadoEdit}
                />)

                : (<EditarOrdenDummy titulo={'Orden ' + seleccionadoEdit.Id} modalAbierto={modalAbierto}
                        setModalAbierto={setModalAbierto}
                        seleccionadoEdit={seleccionadoEdit} setBuscar={setBuscar} buscar={buscar}
                />)
        )
        : null
    return (
        <Paper className={classes.root}>
            {tabla}
            {modal}
        </Paper>
    );
}