import React,{useState} from 'react'
import {Table, TableBody, TableCell,
        TableHead,TableRow,TableContainer,
    } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
    const useStyles = makeStyles((theme) => ({
        root: {
          '& > *': {
            margin: theme.spacing(1),
            //width: '20ch',
          },          
          selectEmpty: {
            marginTop: theme.spacing(2),
          },                 
          table: {
           // maxWidth: '630',
          },
          tableRow: {
              backgroundColor: theme.palette.primary.main,
             // maxWidth: '630px',
          },
          alturaTabla:{
            minHeight: 280,
          },
          tableCellHeader: {
            witdh: 'auto',
            backgroundColor: theme.palette.primary.main,
            color:'white'
          },        
          TableCell: {
           // witdh: 'auto',
           //borderColor: theme.palette.primary.main
          },        
          tableCellSeleccionada: {
            color:'white',           
          },
        } 
      }));
    const columnas=[
        {   id:1,titulo:"Orden" ,alinear:"center"
        },
        {   id:11, titulo:"Tipo" ,alinear:"center"
        },
        {   id:8, titulo:"F.Orden" ,alinear:"center"
        },
        {   id:2, titulo:"Descripción" ,alinear:"center"
        },
        {   id:3, titulo:"Terminal",alinear:"center"
        },
        {   id:9, titulo:"FolioCierre",alinear:"center"
        },
        {   id:10, titulo:"Lugar",alinear:"center"
        },
        {   id:12, titulo:"Estatus" ,alinear:"center"
        },
        {   id:13, titulo:"Ref. Manual",alinear:"center"
        },
        {   id:14, titulo:"Periodo",alinear:"center"
        },
        {   id:15, titulo:"FFinaliza",alinear:"center"
        },
        {   id:16, titulo:"Captura",alinear:"center"
        },
        {   id:17, titulo:"FCancel",alinear:"center"
        },
        {   id:18, titulo:"CancelCausa",alinear:"center"
        },
        {   id:19, titulo:"Turno",alinear:"center"
        },
        {   id:20, titulo:"Ubicación",alinear:"center"
        },
        {   id:21, titulo:"RefTotal",alinear:"center"
        },
        {   id:22, titulo:"UsrFinal",alinear:"center"
        },
      /*   {   id:4, titulo:"Actividad",alinear:"center"
        }, */
        {   id:5, titulo:"Folio",alinear:"center"
        },
        {   id:6, titulo:"Actv. Descr",alinear:"center"
        },
        {   id:7, titulo:"Solución",alinear:"center"
        },
        {   id:23, titulo:"Actv. RefMan",alinear:"center"
        },
        {   id:24, titulo:"Actv. FSoluc",alinear:"center"
        },
        {   id:25, titulo:"Actv. FReg",alinear:"center"
        },
        {   id:26, titulo:"Actv. Estatus",alinear:"center"
        },
        {   id:27, titulo:"Actv. Tipo",alinear:"center"
        },
        {   id:28, titulo:"Actv. Usu",alinear:"center"
        },
    ];
    
const TablaFiltro = ({datos}) => {   
    const classes = useStyles(); 
    const [alturaTabla] = useState(window.innerHeight<1300 ? window.innerHeight-260 : window.innerHeight-110)
    const [idBotonEditar, setIdBotonEditar] = useState(0);
    let celdaVacia=<TableCell />

   const tablaSinResultados =()=>{
       return(
         <TableRow>
           {celdaVacia}
           {celdaVacia}
           {celdaVacia}
           {celdaVacia}
           <TableCell align="center" padding={"none"} style={{color: '#51ad32',fontWeight:600}} className={classes.TableCell}>
             Sin
           </TableCell>
           {celdaVacia}
           {celdaVacia}
           {celdaVacia}
           <TableCell align="center" padding={"none"} style={{color: '#51ad32',fontWeight:600}} className={classes.TableCell}>
             Resultados
           </TableCell>
           {celdaVacia}
           {celdaVacia}
           {celdaVacia}  
           {celdaVacia}              
         </TableRow>
       )
   }

   function handleClickEditar(row) {
        if (row.Id !== idBotonEditar) {
        setIdBotonEditar(row.Id);
        }
    }
    const celda = (alinear, estilo, contenido) => {
        return (
          <TableCell
            align={alinear} className={classes.tableCell}
            padding={"none"} style={estilo} 
          >
            {contenido}
          </TableCell>
        );
    };

   const Tabla=()=>{
    return(
    <TableContainer className={classes.alturaTabla} style={{height:alturaTabla,width:datos.length!==0 ?200+'rem':100+'rem'}}>
    <Table className={classes.table} size="small" 
        aria-label="resultados" stickyHeader>
        <TableHead>
            <TableRow>                
            {  columnas.map((row) => (
                <TableCell key={row.id} padding={"none"} 
                    align="center" className={classes.tableCellHeader}>
                    {row.titulo}
                </TableCell>
            ))}                    
            </TableRow>
        </TableHead>
        <TableBody>                    
        {   datos.length!==0  ? datos.map((row) => (
            <TableRow
                key={row.id} onClick={() => handleClickEditar(row)}
                className={ row.Id === idBotonEditar ? classes.tableRow : ""}
                style={{backgroundColor:row.colRow}}
            >                     
                {celda("center", {width:55+'px'}, row.id)}               
                {celda("left", {paddingRight:7}, row.tipo)}
                {celda("left", {paddingRight:7}, row.fechaOrd)}
                {celda("left", {}, row.descr)}
                {celda("center", {width:80+'px'}, row.terminal)}
                {celda("center", {width:40+'px'}, row.folioCierre)}
                {celda("center", {width:40+'px'}, row.lugar)}
                {celda("center", {}, row.std)}
                {celda("center", {}, row.refmanual)}
                {celda("center", {}, row.periodo)}

                {celda("center", {}, row.fFinaliza)}
                {celda("center", {}, row.capturo)}
                {celda("center", {}, row.fCancel)}
                {celda("center", {}, row.cancelCausa)}
                {celda("center", {}, row.turno)}
                {celda("center", {}, row.ubic)}
                {celda("center", {}, row.refTotal)}
                {celda("center", {}, row.usrFinal)}
                {/* <TableCell align="center" padding={"none"} >
                    {row.actId}
                </TableCell> */}                
                {celda("center", {width:40+'px'}, row.folioCierreAct)}                         
                {celda("left", {width:150+'px'}, row.descrAct)}
                {celda("left", {}, row.solucion)}

                {celda("center", {}, row.actRefMan)}
                {celda("center", {}, row.actFSol)}
                {celda("center", {}, row.actFReg)}
                {celda("center", {}, row.actStd)}
                {celda("center", {}, row.actTipo)}
                {celda("center", {}, row.actUsu)}
            </TableRow>
        )) : tablaSinResultados()}
        </TableBody>
    </Table>
    </TableContainer>)
   }

   return(
    <span>
        {Tabla()}
    </span>
   )  
}

export default TablaFiltro