import React,{useRef,useEffect} from "react";
import { Line, Chart} from "react-chartjs-2";
//import Chart from "chart.js/auto";
//import trendlineLinear from "chartjs-plugin-trendline";

export default function GraficaMensual({dataGrafica}) {
 
  const lineRef = useRef();  


  useEffect(() => {

   // lineRef.current.ChartJS.plugins.registe(chartTrendline);
  }, [])
  
  const data = {
    labels:dataGrafica.labels,
    datasets: [
      {
        label: dataGrafica.estacion==="Bucle A"?"Estacion 1"
          :dataGrafica.estacion ==="Bucle B" ?"Estacion 5C" :"Estacion 5B" ,
        backgroundColor: "#51ad32",
        borderColor: "#51ad32",
        data: dataGrafica.data,
        tension: 0.4,
      },
    ],
  };

  return (
    <>
 {/*    <button onClick={()=>{    
      const prb=lineRef.current
      console.log(prb);
      
      prb.pluginService.register(trendlineLinear)
      console.log(prb )
  }}>prb</button> */}
    <Line ref={lineRef}
    width={200} height={150}
      data={data}
       options={{
      plugins:{ trendlineLinear: {
          colorMin: "red",
          colorMax: "green",
          lineStyle: "solid",
          width: 4,
        //  xAxisKey: "time" (optional),
//          yAxisKey: "usage" (optional),
  //        projection: true|false (optional)
        },},  

        animation: false,
       /* plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const index = context.dataIndex;
                const valor = context.dataset.data[index];
                const bucle = !graficaFiltrada ? labelGrafica : "";
                const label =
                  bucle +
                  " " +
                  arrayMedidores[index] +
                  " " +
                  valor.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
                return label;
              },
            }, 
          },
        },*/

        scales: {
          y: {
            ticks: {
              callback: function (value) {
                return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
              },
            },
          },
          x: {},
        },
      }}
    />
    </>
  );
}
