import {createMuiTheme} from '@material-ui/core/styles'
//import { deepOrange } from '@material-ui/core/colors';
let colorBarra="#51ad32";//claro
let colorBarraHover="#73bd5b";// tenue
let colorBackBarra="#d6e6d1";//mas tenue
const MisTemas = createMuiTheme( {
  palette:
  { // type: 'dark',
    primary:{
      light: '#73bd5b',
      main:'#51ad32',
      dark:'#387923',        
      contrastText: '#fff'
    },
    secondary:
    {
      light: '#529d39',
      main:'#278508',
      dark:'#1b5d05',        
      contrastText:'#fff'
    },
    success: 
    {
      light: '#049b4f',
      main:'#06DE72',
      dark:'#37e48e',        
      contrastText:' rgba(0, 0, 0, 0.87)'
    },
  },
  //modifique el multiline para que se expanda la caja
  overrides: { 
    MuiCssBaseline: {
      "@global": {
        body: { // el hexadecimal  es para el backgroundColor de la barra  #edfaff
          //el hexadecimal  es para el color de la barra #F46239 
          //el hexadecimal  es para el color del hover de la barra #c4e6f2 
          scrollbarColor: colorBarra+" "+colorBarraHover, //"#F46239 #c4e6f2",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: colorBackBarra,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: colorBarra,
            minHeight: 24,
            border: "3px solid "+colorBarra,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
            backgroundColor: colorBackBarra,
          },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
            backgroundColor: colorBackBarra,
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: colorBarraHover,
          },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: colorBackBarra,
          },
        },
      },
    },
  }
})
 
export default MisTemas;